.task_btn {
  @apply transition-colors duration-default;
  @apply px-6 py-3;
  @apply w-full;
  @apply flex items-center justify-between;
  @apply gap-2;
}

.task_btn > span {
  @apply text-sm;
}

.task_btn > svg {
  @apply transition-transform duration-default;
  @apply text-xs;
}

.task_btn:last-child {
  @apply rounded-b-xl;
}

.check_btn,
.follow_btn {
  @apply text-center;
  @apply flex items-center justify-center;
}

.check_btn {
  background: var(--gradient-primary);
}

.follow_btn {
  @apply bg-white/15;
  @apply hover:bg-white/[18%];
}
