.main {
  @apply flex items-center gap-2;
}

.label {
  @apply font-semibold;
  @apply text-white;
  @apply rounded;
  @apply py-1 px-2;
  background: var(--gradient-primary);
}

.value {
  @apply text-white;
  @apply font-medium;
}

.xs > .label {
  @apply text-xs;
}

.xs > .value {
  @apply text-sm;
}

.sm > .label {
  @apply text-xs xxs:text-sm;
}

.sm > .value {
  @apply text-sm xxs:text-base;
}

.md > .label {
  @apply text-sm xxs:text-base;
}

.md > .value {
  @apply text-base xxs:text-lg;
}

.lg > .label {
  @apply text-base xxs:text-lg;
}

.lg > .value {
  @apply text-xl xxs:text-2xl;
}
