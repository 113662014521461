.task_info_html {
  @apply my-2;
  @apply text-white/60;
}

.task_info_html p {
  @apply mb-0;
}

.task_info_html p + p {
  @apply mt-5;
}

.task_info_html ol {
  @apply m-0 p-0 mb-8;

  counter-set: list;
  counter-reset: list;
}

.task_info_html ol > li {
  counter-increment: list;

  @apply flex gap-2 font-medium;
}

.task_info_html ol > li + li {
  @apply mt-6;
}

.task_info_html ol > li:before {
  content: counter(list);

  @apply flex items-center justify-center;
  @apply rounded-md;
  @apply bg-white/[8%];
  @apply w-6 h-6;
  @apply font-semibold;
  @apply pb-[1px];
  @apply flex-shrink-0;
}

.task_info_html a {
  @apply text-green;
  @apply max-w-52;
  @apply overflow-hidden;
  @apply text-ellipsis;
  @apply text-nowrap whitespace-nowrap;
}
