.main {
  @apply bg-transparent;
  @apply px-3 py-1;
  @apply rounded-md;
  @apply text-sm font-medium;
}

.live {
  @apply bg-green;
}

.completed {
  @apply bg-orange;
}
