.main {
  @apply inline;
  @apply w-4 h-4;
}

.sm {
  @apply w-4 h-4;
}

.md {
  @apply w-5 h-5;
}
