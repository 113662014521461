.main {
  @apply w-full;
  @apply bg-white/[8%];
  @apply rounded-lg;
  @apply p-3;
  @apply flex flex-col items-center justify-between;
}

.main > h1 {
  @apply text-xl xxs:text-2xl font-medium text-center;
  @apply mb-1;
}

.total_value {
  @apply w-full;
  @apply flex flex-col items-center justify-between;
  @apply text-3xl xxs:text-4xl font-semibold;
}

.total_value:not(:last-child) {
  @apply border-b border-b-white/10;
  @apply pb-4 mb-4;
}

.total_value > .total_value_label {
  @apply text-white/60;
  @apply flex items-center gap-2;
  @apply text-sm xxs:text-base font-normal;
}

.total_value > .total_xp {
  background: var(--gradient-primary);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  @apply mb-1;
}
