.main {
  @apply flex flex-col items-center justify-center;
  @apply gap-6;
}

.title {
  @apply text-3xl;
}

.general_rules {
  background: var(--gradient-secondary);

  @apply w-full;
  @apply p-[1px];
  @apply rounded-lg;
}

.general_rules_inner {
  @apply relative;
  @apply bg-black;
  @apply rounded-lg;
  @apply px-3 py-1;

  background-repeat: no-repeat;
  background-size: 240px;
  background-position: top -80px right -120px;
}

.general_rules_inner > h3 {
  @apply text-2xl font-medium;
  @apply my-3;
}

.general_rules_inner > p {
  @apply text-sm;
  @apply my-5;
}

.steps {
  @apply flex flex-col items-start gap-5;
  @apply mt-4;
}

.step > h2 {
  @apply text-xl;
  @apply mb-4;
}

.step > img {
  @apply w-full h-auto;
}

.step_cases_rarity {
  @apply flex items-center justify-center flex-wrap;
}

.step_cases_rarity > div {
  @apply flex flex-col items-center justify-center;
  @apply max-w-28 xxs:max-w-28;
  @apply font-medium text-sm;
}

.step_cases_rarity > div > img {
  @apply -mt-3;
}

.step_prizes {
  @apply grid grid-cols-1;
  @apply border border-white/15;
  @apply rounded-lg;
}

.step_prizes_item {
  @apply grid grid-cols-3;
  @apply items-center justify-center;
}

.step_prizes_item:not(:last-child) {
  @apply border-b border-b-white/15;
}

.step_prizes_item > span {
  @apply px-3;
}

.step_prizes_item > div {
  @apply col-span-2;
  @apply grid grid-cols-3;
}
