.main {
  @apply flex flex-col items-center justify-center;
  @apply min-h-[80vh];
  @apply px-4;
}

.container {
  @apply flex flex-col items-center;
  @apply text-center;
  @apply p-6;
}

.highlight,
.wallet {
  @apply font-medium;
  @apply text-lg;

  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.connect_wallet_img {
  @apply w-full max-w-56 my-4;
}

.wallet_connected_img {
  @apply w-full max-w-44 my-6;
}

.error {
  @apply text-center text-sm;
  @apply my-6;
}
