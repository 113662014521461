.main {
  @apply relative;
  @apply grid grid-flow-row;
  @apply rounded-lg;
  @apply overflow-hidden;
}

.task_main_section_outer.highlight {
  background: var(--gradient-primary);

  @apply p-[1px];
  @apply rounded-t-lg;
}

.task_main_section_inner {
  @apply bg-black;
  @apply rounded-t-lg;
}

.task_main_section_container {
  @apply cursor-pointer;
  @apply transition-colors duration-default;
  @apply bg-white/[4%];
  @apply hover:bg-white/[6%];
  @apply p-6;
}

.header {
  @apply flex items-center justify-between gap-x-2;
  @apply mb-2;
}

.title {
  @apply text-lg;
  @apply font-medium;
}

.title_info {
  @apply flex items-center gap-2;
}

.completed_label {
  @apply w-fit ml-auto;
  @apply mt-2;
  @apply bg-green;
  @apply rounded-lg;
  @apply px-4 py-1;
  @apply text-sm;
  @apply font-medium;
}

.description {
  @apply text-sm text-white/60;
}

.icon_rotated {
  @apply rotate-180;
}

.task_info_section {
  @apply bg-white/[8%];
  @apply px-6 py-3;
  @apply text-sm;
}

.task_main_section:first-child {
  @apply rounded-t-xl;
}

.task_main_section:last-child {
  @apply rounded-b-xl;
}

.task_btn {
  @apply transition-colors duration-default;
  @apply px-6 py-3;
  @apply w-full;
  @apply flex items-center justify-between;
  @apply gap-2;
}

.expand_btn {
  @apply bg-white/[8%];
  @apply hover:bg-white/[10%];
}

.task_completed_indicator {
  @apply absolute left-0 h-full;
  @apply w-1;
  @apply bg-green;
  @apply pointer-events-none;
}
