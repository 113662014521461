.main {
  @apply flex flex-col items-center justify-center;
  @apply relative;
  @apply overflow-hidden;
  @apply -mx-2;
  @apply pb-0 xxs:pb-2;
}

.main > img {
  @apply absolute top-0;
  @apply aspect-[1080/407] object-cover;
  @apply w-auto min-h-40;
  @apply -z-10;
  @apply pointer-events-none;
}

.main > h1 {
  @apply text-4xl font-medium;
  @apply text-center;
  @apply mb-0;
}

.how_btn {
  background: var(--gradient-primary);

  @apply px-3 py-2;
  @apply rounded-md;
  @apply my-4;
}
