.main {
  @apply inline-flex items-center justify-center gap-1;
}

.main > span {
  @apply font-medium;
  @apply border-b border-b-white/40;

  line-height: 18px;
  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.main > svg {
  @apply text-sm;
  @apply rotate-45;
  @apply text-white/60;
}
