.main {
  @apply flex flex-col;
}

.title {
  @apply text-2xl font-medium;
  @apply border-b border-b-white/10;
  @apply pb-4;
}

.items {
  @apply flex flex-col;
}

.item {
  @apply py-4;
  @apply border-b border-b-white/10;
  @apply text-start;
}

.question {
  @apply flex items-start justify-start gap-3;
}

.question > svg {
  @apply text-lg mt-[2px];
  @apply flex-shrink-0;
}

.answer {
  @apply text-white/50;
  @apply mt-2 ml-7;
}
