.main {
  @apply flex flex-col items-center gap-6;
  @apply w-full;
}

.leaderboard_type_tabs {
  @apply w-full max-w-80;
}

.leaderboard_stages {
  @apply w-full;
}
