@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource-variable/inter';

@layer base {
  :root {
    --gradient-primary: linear-gradient(97.88deg, #11a97d 0%, #6610f2 119.44%);
    --gradient-secondary: linear-gradient(90deg, #fa709a 0%, #fee140 100%);
  }
}

body {
  @apply font-primary;
  @apply bg-black;
  @apply text-white;
  @apply min-h-screen;
}

body.no-scroll {
  @apply max-h-screen;
  @apply overflow-hidden;
}

button:disabled {
  @apply opacity-50;
}
