.main {
  @apply flex flex-col items-center;
  @apply w-full px-2 my-2;
}

.title {
  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  @apply w-full text-center;
  @apply text-3xl font-semibold;
  @apply mb-2;
}

.prize_list {
  @apply flex flex-col items-center;
}

.prize {
  @apply w-full;
}

.prize_outer {
  @apply w-full;
  @apply p-[1px];
  @apply rounded-2xl;

  background: var(--gradient-secondary);
}

.prize_inner {
  @apply flex flex-col items-center justify-center;
  @apply text-center;
  @apply bg-black;
  @apply rounded-2xl;
  @apply p-1;
}

.prize_value {
  background: var(--gradient-secondary);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  @apply text-xl font-medium;
}

.prize_description {
  @apply text-sm;
}

.prize:not(:last-child):after {
  content: '+';

  background: var(--gradient-secondary);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  @apply flex items-center justify-center;
  @apply text-center align-middle;
  @apply text-4xl;
  @apply mb-1;
}
