.main {
  @apply fixed bottom-3;
  @apply flex items-center justify-center;
  @apply w-full;
}

.menu {
  @apply w-full max-w-96;
  @apply grid grid-cols-5 gap-1;
  @apply mx-1 p-1;
  @apply bg-menu;
  @apply rounded-lg;
}

.menu_item {
  @apply px-1 py-1;
  @apply flex flex-col gap-[2px] xxs:gap-1 items-center justify-center;
  @apply text-[10px] xxs:text-[11px] font-semibold;
  @apply rounded-md;
  @apply hover:bg-white/[8%];
  @apply transition-colors duration-default;
}

.menu_item > svg {
  @apply flex-shrink-0;
  @apply w-5 xxs:w-6 h-auto;
}

.menu_item_highlighted {
  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.menu_item_active {
  @apply bg-white/[16%] hover:bg-white/[16%];
  @apply text-green;
}
