.main_container {
  @apply bg-white/[4%];
  @apply p-4 rounded-[12px];
  @apply text-center;
  @apply flex flex-col;
}

.title {
  @apply text-3xl;
  @apply font-medium;
  @apply mb-1;
}

.title_description {
  @apply font-medium;
  @apply text-xs;
  @apply mb-3;
}

.link_button {
  @apply bg-white;
  @apply p-4;
  @apply rounded-md;
  @apply w-full;
  @apply flex items-center justify-center;
  @apply mb-2;
}

.link_button > span {
  @apply text-black;
  @apply font-medium text-sm;
}

.link_button > svg {
  @apply w-5 h-5;
  @apply ml-2;
}

.xp_description {
  @apply font-medium;
  @apply text-[13px];
  @apply mb-3;
}

.xp_description > span {
  @apply text-green;
}

.total_table {
  @apply grid grid-cols-1;
  @apply border border-white/10;
  @apply rounded-md;
  @apply mb-3;
}

.total_table_row {
  @apply grid grid-cols-3;
  @apply p-2;
  @apply text-sm;
  @apply font-medium;
}

.total_table_row:not(:last-child) {
  @apply border-b border-b-white/10;
}

.row_header {
  @apply text-white/60;
  @apply text-base;
  @apply font-medium;
}

.total_table_row > div {
  @apply flex items-center justify-center;
}

.level_label > div {
  @apply flex items-center;
  @apply bg-white/[8%];
  @apply text-white/60;
  @apply font-semibold;
  @apply text-sm;
  @apply px-[6px] py-[1px];
  @apply rounded-sm;
  @apply uppercase;
}
