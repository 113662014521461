.main {
  @apply grid grid-cols-2 xxs:grid-cols-3 gap-[2px];
  @apply bg-white/[8%];
  @apply rounded-md;
  @apply overflow-hidden;
}

.stage {
  @apply transition-colors duration-default;
  @apply flex items-center justify-center gap-2;
  @apply text-center;
  @apply px-5 py-3;
  @apply hover:bg-white/[4%] disabled:hover:bg-transparent;
  @apply rounded-md;
  @apply text-sm font-medium;
}

.stage_claim {
  @apply col-span-2 xxs:col-span-3;
}

.stage > svg,
.stage > img {
  @apply w-5 h-5;
}

.stage_active,
.stage_claim_active {
  @apply bg-white/[8%];
  @apply hover:bg-white/[8%];
}

.stage_completed {
  @apply text-green;
}

.stage_claim:disabled > span {
  @apply text-white/60;
}

.stage_claim > span {
  background: var(--gradient-secondary);

  @apply text-transparent;
  @apply bg-clip-text;
}
