.main {
  @apply w-full;
  @apply flex items-center justify-around flex-wrap gap-[1px];
  @apply bg-white/[8%];
  @apply rounded-md;
  @apply overflow-hidden;
}

.tab {
  @apply flex-1 min-w-32;
  @apply transition-colors duration-default;
  @apply flex items-center justify-center gap-2;
  @apply text-center;
  @apply px-5 py-3;
  @apply hover:bg-white/[4%] disabled:hover:bg-transparent;
  @apply text-white/60;
  @apply rounded-md;
  @apply text-sm;
}

.tab_active {
  @apply bg-white/[8%];
  @apply text-white;
  @apply hover:bg-white/[8%];
  @apply font-medium;
}

.main.highlighted {
  @apply bg-white font-medium;
}

.main.highlighted > .tab:not(.tab_active) {
  background: var(--gradient-primary);

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.main.highlighted > .tab_active {
  background: var(--gradient-primary);

  @apply text-white;
}
