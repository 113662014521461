.main {
  @apply w-full;
  @apply flex flex-col gap-4;
}

.list_wrap {
  @apply w-full;
  @apply border border-white/15;
  @apply rounded-lg;
  @apply overflow-hidden;
}

.list {
  @apply w-full;
  @apply text-sm;
  @apply border-collapse;
  @apply border-spacing-0;
}

.list tr.header,
.list tr:not(:last-child) {
  @apply border-b border-b-white/15;
}

.list tr > th,
.list tr > td {
  @apply text-left last:text-right;
  @apply px-4 py-2;
}

.header {
  @apply text-white/60;
  @apply font-medium;
}

.item {
  @apply odd:bg-white/5;
}

.rank > div {
  @apply w-auto min-w-6;
  @apply inline-flex items-center justify-center;
  @apply text-white/60 bg-white/[8%];
  @apply rounded-md;
  @apply font-medium;
  @apply px-1 py-[1px];
}

.item_current_user {
  @apply text-green;
}

.item_current_user .rank > div {
  @apply !bg-green !text-white;
}

.load_more {
  @apply bg-white/15;
  @apply p-2;
  @apply rounded-lg;
  @apply text-sm;
}
