.main {
  @apply overflow-hidden;
  @apply fixed top-0 left-0 right-0 bottom-0;
  @apply flex items-center justify-center;
}

.main > img {
  @apply aspect-square;
  @apply w-12 h-auto;

  @apply animate-spin;
}
