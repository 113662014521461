.main {
  @apply flex flex-col items-center gap-10;
}

.instructions {
  @apply flex flex-col items-center;
  @apply text-center;
}

.instructions > span {
  @apply max-w-80;
}

.connect_wallet_img {
  @apply w-full max-w-64 h-auto;
}

.wallet_connected_img {
  @apply w-full max-w-52 h-auto;
}

.space {
  @apply mt-6 mb-8;
}

.highlight {
  @apply font-medium;

  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
