.main {
  @apply sticky top-0;
  @apply bg-black;
  @apply w-full;
  @apply py-3 px-2 xxs:px-4;
  @apply flex items-center justify-between gap-x-4;
  @apply z-50;
}

.brand {
  @apply flex items-center gap-2;
  @apply pointer-events-none;
}

.brand > svg {
  @apply hidden xxs:inline;
  @apply w-8 h-8;
}

.brand > span {
  @apply font-medium;
  @apply text-sm;
}

.total_label {
  @apply flex items-center gap-x-3;
  @apply text-white/60;
  @apply text-lg;
}

.total_value {
  @apply text-white;
  @apply font-semibold;
  @apply text-xl;
}
