.main_outer {
  background: var(--gradient-secondary);

  @apply rounded-lg;
  @apply p-[1px];
}

.main_inner {
  @apply bg-black;
  @apply rounded-lg;
  @apply flex flex-col items-center gap-3;
  @apply p-4;
}

.header {
  @apply flex items-center justify-center gap-3;
}

.title {
  @apply text-3xl font-medium;
}

.description {
  @apply text-white/60;
  @apply text-sm text-center;
}

.description > p + p {
  @apply mt-3;
}

.claim_reward {
  @apply flex flex-col items-center justify-center gap-4;
  @apply p-6;
}

.claim_reward > span {
  @apply text-center;
  @apply text-white/40;
  @apply max-w-56;
}

.claim_reward > img {
  @apply w-full max-w-52 h-auto;
  @apply aspect-[580/624];
}

.claim_reward > button {
  background: var(--gradient-secondary);

  @apply text-black;
  @apply font-semibold;
  @apply px-6 py-2;
  @apply rounded-lg;
}

.venom_network {
  background: var(--gradient-secondary);

  @apply text-transparent;
  @apply bg-clip-text;
}
