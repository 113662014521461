.main_outer {
  background: var(--gradient-secondary);

  @apply p-[1px];
  @apply rounded-lg;
  @apply w-full;
}

.main_inner {
  @apply relative;
  @apply flex flex-col items-center justify-center;
  @apply bg-black;
  @apply rounded-lg;
  @apply text-center;

  background-repeat: no-repeat;
  background-size: 225px 225px;
  background-position:
    top -90px left -85px,
    bottom -90px right -120px;
}

.title {
  @apply text-white;
  @apply text-4xl font-medium;
  @apply mt-6 mb-2;
}

.prize {
  @apply flex flex-col items-center;

  background: var(--gradient-secondary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.prize_value {
  @apply text-3xl font-medium;
}

.prize_description {
  @apply text-lg font-medium;
  @apply mb-10;
}
