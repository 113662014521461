.main {
  @apply flex flex-col items-center justify-center;
  @apply text-center;
  @apply min-h-[80vh];
}

.main > img {
  @apply w-full max-w-80 h-auto;
}

.description {
  @apply mt-12;
  @apply text-white/80;
}

.error {
  @apply mt-3;
  @apply text-xs text-white/60;
}
